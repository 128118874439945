/* Utils */
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-radius: 16px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
    &.datatable{
        padding: 2rem 0;
    }
    .pedding-area {
        padding: 0 2rem;
    }
}


.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}

.p-inputtext {
    font-size: 1rem;
    color: #495057;
    background: #F8F9FA;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    border-color: rgba(145, 158, 171, 0.2);
    transition: border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    appearance: none;
    border-radius: 6px;
}
.p-component {
    font-size: 0.875rem;
}
.p-inputtext.p-invalid.p-component {
    border-color: rgb(255, 86, 48)
}

.p-error {
    color:  rgb(255, 86, 48);
}

// Datatable
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 0px;
    border-bottom: 1px dashed rgb(241, 243, 244);
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 0 1px 0;
}
.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px dashed rgb(241, 243, 244);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}
// Datatable Header
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 0 0 1px 0;
    border-bottom: 1px dashed rgb(241, 243, 244);
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 0 0 1px 0;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 0;
    padding: 2rem;
}
.p-datatable .p-datatable-header {
    background: white;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
}

.p-datatable .p-datatable-thead > tr > th {   
    background: rgb(244, 246, 248);
    border: 1px dashed rgb(241, 243, 244);
    color: rgb(99, 115, 129);
}

// Datatable Footer
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
   border-width: 0 0 0 0;
}

//Buttons
.p-button {
    padding: 6px 18px;    
    // font-weight: 600;
    // font-size: 16px;
    // border-radius: 8px;
    // transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // cursor: pointer;
    // border: 0px currentcolor;
    // .p-button-label {
    //     font-weight: 600;
    // }
}

//menu
.layout-menu ul a.active-route {
    font-weight: 600;
    color: inherit;
    i {
        color: #3B82F6;
    }
}

.layout-menu ul a {
    color: rgb(99, 115, 129)
}


.layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
    color: rgb(145, 158, 171);
}

