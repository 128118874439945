// .p-datatable-tbody > tr > td {
//   word-break: break-all;
// }

* {
  box-shadow: none !important;
  scrollbar-color: #556080 white;
}

.grid {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  margin-top: -1.5rem;
}

.datatable {
  padding: 0 !important;
}

.empty-column {
  width: 50px !important;
}

.p-datatable-thead>tr>th .p-column-header-content .p-column-title {
  text-align: left;
  text-transform: uppercase;
  border: none;
  color: #556080;
  background: #fff !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  text-align: center !important;
}

.p-datatable .p-datatable-thead>tr>th {
  background-color: white;
  border: none;
}

.p-datatable-tbody>tr>td {
  border: none !important;
}

.p-datatable-tbody>tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.p-datatable-wrapper {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.content-bottom {
  position: relative;
  background: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.content-bottom2 {
  position: absolute;
  width: 100% !important;
  background: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0 !important;
}

.total-value {
  position: absolute;
  right: 20px;
  align-self: center;
  font-size: 12px;
  color: #426598;
}

.layout-main-container {
  height: 100vh !important;
  padding: 5.5rem 2rem 2rem 4rem;
}

.layout-sidebar {
  height: 100vh !important;
  overflow: hidden;
  border-right: none;
}

.layout-menu-container {
  height: 100% !important;
}

.p-inputtext {
  padding: 0 0 0 1rem;
  height: 40px;
  width: 300px;
  border-radius: 10px !important;
  color: #353535;
}

.p-inputtext::placeholder {
  color: #556080;
  font-weight: 400;
}

.p-inputtext:focus {
  border-color: #556080 !important;
  box-shadow: none;
}

.layout-topbar {
  height: 5.5vh;
  left: 225px;
  width: 89%;
  box-shadow: none;
}

.p-menuitem-text {
  color: #090e2c !important;
  font-size: 1rem;
  font-weight: 300;
}

.user-menubar {
  padding: 0.5rem 0.3rem !important;
}

.nusret-paginate .p-paginator-pages {
  display: flex;
  gap: 5px;
}

.nusret-paginate .p-paginator-pages>button {
  font-size: 0.75rem;
  padding: 3px 3px !important;
  color: #737373 !important;
  border: none !important;
}

.nusret-paginate .p-paginator-pages .p-paginator-page {
  min-width: 23px !important;
  height: 23px !important;
}

.nusret-paginate .p-paginator-element {
  padding: 3px 3px !important;
  color: #737373 !important;
  border: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #556080;
  color: #ffffff !important;
  border-radius: 50%;
}

.navigation-text-sm {
  font-size: 0.75rem;
  font-weight: 300;
}

.navigation-text-md {
  font-size: 1rem;
  font-weight: 500;
}

.pi-chevron-right::before {
  content: "\e901" !important;
  font-size: 13px;
  color: #737373;
}

.pi-chevron-down::before {
  content: "\e902" !important;
  font-size: 13px;
  color: #737373;
}

.card {
  box-shadow: none;
}

.main-datatable {
  min-height: 77vh !important;
}

.empty-main-datatable {
  min-height: 75vh !important;
}

.p-row-toggler-icon {
  margin-left: 15px !important;
}

.actions-icons-new {
  width: 18px;
  height: 18px;
}

.custom-split-button .p-splitbutton-menubutton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  height: 40px !important;
}

.p-link:focus {
  box-shadow: none !important;
}

.p-datatable .p-datatable-tbody .p-datatable-row-expansion>td {
  padding: 1rem 0rem !important;
}

.sub-datatable2 .p-datatable-tbody>tr {
  background-color: #f8f9fa;
}

.info-modal-header2 {
  font-size: 1.125rem;
  font-weight: 400;
}

.company-contact-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.p-dialog {
  border: none;
}

.p-dialog-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  border: none;
}

.p-dialog-title {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
}

.p-dialog-content {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border: none;
}

.company-logo-title {
  font-size: 0.75rem;
  font-weight: 200;
  color: #737373;
  margin-top: 10px !important;
}

.company-modal-top-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0;
  margin-bottom: 30px;
}

.modal-content-area {
  padding: 0 150px !important;
}

.modal-input-height {
  height: 3.5vh !important;
  border: 1px solid #88a9da;
}

.modal-input-height:focus {
  border: 1px solid #0aa06e !important;
  background-color: #ffffff;
  font-weight: 400;
}

.modal-input-height2:focus {
  border: 1px solid #0aa06e !important;
  background-color: #ffffff;
  font-weight: 400;
}

.modal-calender-height {
  height: 3.5vh !important;

  input{
    height: 3.5vh !important;
  }
}

.modal-calender-height:focus {
  background-color: #ffffff;
  font-weight: 400;
}

.modal-calender-height2:focus {
  background-color: #ffffff;
  font-weight: 400;
}

.modal-dropdown-height {
  height: 3.5vh !important;
}

.p-filled {
  border: 1px solid #88a9da !important;
  background-color: #ffffff !important;
  font-weight: 400 !important;
}

.modal-input-height2 {
  height: 90px !important;
  border: 1px solid #88a9da;
  resize: none;
}

.company-next-button {
  width: 10rem;
  height: 2.5rem;
  color: #f8f9fa;
  border-radius: 8px;
  background: #556080;
}

.company-next-button .p-ink {
  background: #556080 !important;
}

.company-next-button .p-ink-active {
  background: #556080 !important;
  color: #f8f9fa !important;
}

.company-next-button:enabled:active {
  border: none !important;
  background: #556080;
}

.company-next-button:hover {
  background: #556080 !important;
}

.upload-company-logo>span {
  background-color: #f4f7f8 !important;
  border: 1px solid #88a9da;
}

.p-button:focus {
  box-shadow: none !important;
}

.company-contact-context {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.company-contact-area {
  width: 450px !important;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.contact-right {
  width: 250px !important;
}

.contact-right .formgrid {
  display: flex;
  flex-direction: column;
  width: 250px !important;
}

.margin-clear {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 0 !important;
}

.new-back-button {
  box-shadow: none !important;
  width: 180px !important;
  height: 40px !important;
  color: #851c1c !important;
  padding: 15px !important;
  border: 1px solid #851c1c !important;
  background: #ffffff !important;
  border-radius: 10px !important;
  transition: all 0.3 linear;
}

.new-back-button:hover {
  background: #851c1c !important;
  color: #ffffff !important;
}

.new-save-button {
  box-shadow: none !important;
  width: 180px;
  height: 40px !important;
  color: #ffffff !important;
  padding: 15px !important;
  background: #556080 !important;
  border-radius: 10px !important;
  transition: all 0.3 linear;
}

.new-save-button:disabled {
  background: #d6d7d9 !important;
  opacity: 1 !important;
}

.p-datatable-header {
  background: #f8f9fa !important;
  color: #353535 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 300 !important;
  border: none !important;
  padding: 1rem 2rem !important;
}

.p-datatable-header2 {
  min-height: 40px !important;
  background: #f8f9fa !important;
  color: #353535 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 300 !important;
  border: none !important;
  padding: 1.8rem 2rem !important;
}

.p-datatable-header3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px !important;
  background: #f8f9fa !important;
  color: #353535 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 300 !important;
  border: none !important;
  padding: 1.8rem 2rem !important;
}

.datatable-padding {
  position: relative;
  height: auto !important;
  min-height: 40vh !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.datatable-padding1 {
  position: relative;
  height: auto !important;
  min-height: 33vh !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.datatable-padding2 {
  position: relative;
  height: auto !important;
  min-height: 46.5vh !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.datatable-padding3 {
  position: relative;
  height: auto !important;
  min-height: 61.5vh !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.information-data {
  position: relative;
  height: auto !important;
  min-height: 40vh !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
}

.information-data2 {
  position: relative;
  height: 33vh !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
}

.information-data3 {
  position: relative;
  height: 46vh !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
}

.information-data3 .p-card {
  height: 100% !important;
  border-radius: 10px !important;
}

.information-data4 {
  position: relative;
  height: 26vh !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
}

.information-data4 .p-card {
  height: 100% !important;
  border-radius: 10px !important;
}

.information-data4 .formgrid>div:nth-child(3) {
  width: 100% !important;
}

.information-data5 {
  position: relative;
  height: 53.6vh !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
}

.information-data5 .p-card {
  height: 100% !important;
  border-radius: 10px !important;
}

.information-data6 {
  position: relative;
  height: 81vh !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;

  .p-datatable-wrapper{
    max-height: 70vh;
  }
}

.information-data7 {
  position: relative;
  height: 20vh !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
}

.information-data7 .p-card {
  height: 100% !important;
  border-radius: 10px !important;
}

.information-data6 .p-card {
  height: 100% !important;
  border-radius: 10px !important;
}

.information-data2 .p-card {
  height: 100% !important;
  border-radius: 10px !important;
}

.information-data .p-card {
  border-radius: 10px !important;
}

.datatable-padding .p-datatable-table .p-datatable-tbody>tr>td:nth-child(1) {
  padding-left: 2rem !important;
}

.datatable-padding .p-datatable-table .p-datatable-thead>tr>th:nth-child(1) {
  padding-left: 2rem !important;
}

.comp-information-main {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.comp-information-right .formgrid>div {
  width: 33% !important;
}

.comp-information-right .formgrid>div:nth-child(8) {
  width: 66% !important;
}

.user-information-right .formgrid>div:nth-child(7) {
  width: 99% !important;
}

.new-contact-modal-main .p-dialog-content {
  padding: 2rem 3rem !important;
  text-align: center;
}

.new-contact-modal-main .p-dialog-content .mt-5 {
  margin: 0 !important;
}

.new-contact-modal-main .p-dialog-content .mt-5 .flex {
  gap: 30px !important;
}

.new-contact-modal-main .p-dialog-content .mt-5 .flex .company-modal-top-area {
  margin-bottom: 0 !important;
}

.new-provider-modal-main .p-dialog-content {
  text-align: center !important;
}

.layout-menu li a:hover {
  background-color: transparent !important;
}

.layout-menu li a:hover>span {
  color: #e08eca !important;
}

.layout-menu li a:hover .sidebar-icon {
  stroke: #e08eca !important;
}

.layout-menu li a.router-link-exact-active span {
  color: #e08eca !important;
}

.layout-menu li a.router-link-exact-active .sidebar-icon {
  color: #e08eca;
  fill: currentColor;
}

.layout-menu li a.router-link-exact-active span {
  color: #e08eca !important;
}

.layout-menu li {
  margin-bottom: 30px !important;
}

.p-ink-active {
  animation: none !important;
}

.sidebar-small-title {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
  color: #999999 !important;
  margin-bottom: 30px;
}

.margin-padding-clear {
  margin: 0 !important;
  padding: 0 !important;
  cursor: default !important;
}

.margin-padding-clear:hover .sidebar-small-title {
  color: #999999 !important;
  opacity: 1 !important;
}

.margin-padding-clear1 {
  margin: 0 !important;
  padding: 0 20px 0 20px !important;
}

.layout-menu {
  margin-top: 10px !important;
}

.layout-submenu-wrapper-enter-done{
  padding-left: 15px !important;
  margin-top: 15px !important;

  li{
    margin-bottom: 25px !important;
  }
}

.layout-submenu-wrapper-enter-active{
  padding-left: 15px !important;
  margin-top: 15px !important;

  li{
    margin-bottom: 25px !important;
  }
}

.layout-submenu-wrapper-exit-active{
  padding-left: 15px !important;
  margin-top: 15px !important;

  li{
    margin-bottom: 25px !important;
  }
}

.layout-submenu-wrapper-enter{
  padding-left: 15px !important;
  margin-top: 15px !important;

  li{
    margin-bottom: 25px !important;
  }
}

.layout-submenu-wrapper-exit{
  padding-left: 15px !important;
  margin-top: 15px !important;

  li{
    margin-bottom: 25px !important;
  }
}

.navigation-height-clear {
  min-height: 7vh !important;
  max-height: 7vh !important;
}

.navigation-main-icon {
  padding: 8px 8px;
  width: 40px;
  height: 40px;
  background-color: #556080;
  border-radius: 10px;
}

.navigation-company-logo {
  height: 45px !important;
  width: 45px !important;
  border-radius: 50%;
  margin-right: 1rem !important;
}

.navigation-company-header {
  font-size: 1rem;
  color: #353535;
}

.select-company-text {
  font-weight: 300;
  font-size: 14px;
  color: #737373;
  margin-right: 20px !important;
}

.select-company-dropdown {
  width: 340px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #88a9da;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: #88a9da;
  border-bottom: 1px solid #88a9da !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.p-inputwrapper-focus {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-bottom: none !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background-color: #ffffff !important;
}

.p-dropdown.p-inputwrapper-filled {
  background: #ffffff !important;
}

.p-dropdown {
  background: #f8f9fa;
  border-radius: 8px;
  border: 0.5px solid #88a9da;
  display: flex;
  align-items: center;
  height: 43px;

  .p-dropdown-label {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 1rem;
    line-height: 18.75px;
    color: #737373;

    div {
      gap: 15px;
      display: flex;
      flex-direction: row;

      img {
        width: 30px;
        border-radius: 50%;
      }

      .text {
        font-weight: 300;
        font-size: 1rem;
        line-height: 18.75px;
        color: #0a0f2d;
      }
    }
  }

  .p-dropdown-label.p-placeholder {
    color: #737373;
    display: flex;
    align-items: center;
    font-size: 16px !important;
  }

  .p-dropdown-trigger {
    color: #737373;
  }
}

.p-dropdown-panel {
  border: 1px solid #88a9da;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background: #fff;

  .p-dropdown-header {
    background-color: #fff;
    border: none;
    padding: 15px 10px;

    .p-dropdown-filter-container {
      width: 100%;

      .p-inputtext:enabled:focus {
        outline: none;
        box-shadow: none;
      }

      .p-dropdown-filter {
        border-radius: 10px;
        border: 0.5px solid #88a9da;
        background: #fff;
        width: 100%;
      }

      .p-inputtext {
        font-family: "Inter";
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: #737373;
      }
    }
  }

  .p-dropdown-items-wrapper {
    scrollbar-color: #556080 transparent !important;
    scroll-margin-right: 5px !important;

    .p-dropdown-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .p-dropdown-item.p-highlight {
        color: #0a0f2d;
        background: #007bff;
      }

      .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        background: transparent;
        cursor: pointer;
      }

      .p-dropdown-item.p-highlight {
        background-color: transparent;
      }

      .p-dropdown-item {
        height: 30px;
        padding: 0 1rem;
        display: flex;
        align-items: center;

        :not(.p-highlight):not(.p-disabled):hover {
          background: transparent;
          cursor: pointer;
        }
      }

      .p-dropdown-item div {
        gap: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 30px;
          border-radius: 50%;
        }

        .text {
          font-weight: 300;
          font-size: 1rem;
          line-height: 18.75px;
          color: #0a0f2d;
        }
      }
    }
  }
}

.user-padding-left .p-datatable-tbody tr td:first-child,
.user-padding-left .p-datatable-thead tr th:first-child {
  padding-left: 40px !important;
}

.p-datatable-tbody tr td {
  font-size: 14px;
  color: #353535;
  font-weight: 400;
}

.p-splitbutton-menubutton {
  background: transparent !important;
  width: 1rem !important;
  margin: 0 0 0 10px !important;
}

.p-column-header-content .p-sortable-column-icon {
  color: #556080 !important;
  margin-left: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-column-header-content .p-sortable-column-icon::before {
  font-size: 10px !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: transparent;
  color: #212529;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: transparent;
  color: #212529;
}

.card {
  border-radius: 10px !important;
}

.list-component-height .p-datatable-tbody tr {
  min-height: 40px !important;
  max-height: 40px !important;
}

.user-info-modal-detail .p-dialog-content {
  padding: 40px 0 50px 0 !important;
}

.new-user-image-wrapper {
  min-height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.organization-select-area .flex-column div {
  margin-bottom: 1rem ;
}

.organization-select-area .p-dropdown-trigger{
  margin-bottom: 0 !important;
}

.p-datatable .p-datatable-tbody tr:hover {
  background: #f1f1f1;
}

.empty-table-card .p-card-body {
  display: none !important;
}

.permission-setting-left-area {
  gap: 3vh;
  display: flex;
  flex-direction: column;
}

.left-area-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.permission-setting-right-area {
  display: flex;
  gap: 15px;
  padding: 0 30px;
}

.category-header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  color: #88a9da;
}

.permission-role-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #88a9da;
  background: #ffffff;
  color: #000000;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 300;
  min-width: 5rem;
  height: 3vh;
}

.permission-role-button:hover {
  background: #88a9da !important;
  color: #ffffff !important;
  border: 1px solid #88a9da !important;
}

.description-full {
  padding: 50px 70px 0 70px;
}

.description-full .formgrid div:nth-child(3) {
  width: 100% !important;
}

.userlist-scroll {
  padding: 10px 20px;
  max-height: 400px;
  min-height: 400px;
}

.input-margin-clear .formgrid .field {
  margin-bottom: 0 !important;
}

.radio-select-area {
  height: 100px;
  background: #f8f9fa;
  padding: 20px 50px;
  border-radius: 10px;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #556080;
  background: #556080;
}

.agreement-button {
  border: 1px solid #556080 !important;
  color: #556080;
  border-radius: 10px;
  height: 40px;
  background: #f8f9fa;
}

.agreement-button:hover {
  border: 1px solid #556080 !important;
  color: #556080 !important;
  border-radius: 10px !important;
  background: #f8f9fa !important;
}

.padding-clear-card .p-card-body {
  padding: 0 !important;
}

.metadata-width-adjust .formgrid .field {
  width: 16.6%;
}

.metadata-width-adjust .formgrid .field {
  margin-bottom: 0 !important;
}

.datatable-margin-bottom {
  margin-bottom: 50px;
}

.datatable-scroll-adjust .p-datatable-wrapper {
  max-height: 40vh !important;
}

.timeline {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

[class^="timeline-icon-"] {
  font-size: 2rem;
  margin-bottom: 5px;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
  }
}

.trend-detail-wrapper {
  display: flex;
  justify-content: center;

  .p-datatable {
    width: 45vw;

    .p-datatable-wrapper {
      max-height: 30vh;
    }
  }
}

.copy-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 3rem;
  margin-top: 2rem;
}

.copy-wrapper .flex {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.copy-table-wrapper {
  width: 100%;
}

.copy-table-wrapper .p-datatable-wrapper {
  max-height: 36vh;
}

.p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #f8f7f7;
  border-radius: 15px;
  color: #212529;
  width: 393px;
  height: auto;
  padding: 1.25rem;
}

.p-organizationchart-node-content.collapsed {
  height: 200px;
  /* Set height when collapsed */
}

.add-kpi-modal-max-height .p-datatable-wrapper {
  max-height: 30vh;
}

.add-kpi-modal-margin-adjust .formgrid .field {
  margin-bottom: 2rem !important;
}

.p-datatable-row-expansion:hover {
  background: inherit !important;
}

.p-datatable-row-expansion .p-datatable-thead tr:hover{
  background: inherit !important;
}

.p-datatable-footer{
  background: #F8F9FA !important;
  color: #556080 !important;
  border: none !important;
  font-weight: 500 !important;
  
}

.metadata-width-adjust-kpi .formgrid .field {
  width: 30%;
}

.targets-datatable .p-datatable-wrapper {
  max-height: 75vh !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.create-agreement-wrapper {
  min-height: 82vh !important;
  background: #ffffff;
  border-radius: 10px !important;
  justify-content: center;
  display: flex;
  gap: 30px;
  padding: 30px;

  .metadata-field {
    position: relative;
    width: 32%;
    height: 76vh;
    border-radius: 10px;
    background: #f0f0f0;

    .show-edit-metadata {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      cursor: pointer;
      opacity: 1;
    }

    .metadata-header {
      height: 8vh;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #556080;
      margin: 0 10px;
      font-weight: 600;
      font-size: 1rem;
      color: #556080;
    }

    .metadata-body {
      height: 89%;
      background: transparent;
      width: 100%;
      overflow: auto;
      padding: 3vh 2vh;
      scrollbar-color: #556080 transparent !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .metadata-property {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        height: 100%;

        .agreement-type-tab-pane {
          display: flex;
          flex-direction: row;
          height: 38px;
          border-radius: 8px;
          background: #FFFFFF;

          .agreement-type {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: center;
            cursor: pointer;
            background-color: transparent;

            label {
              color: #353535;
              text-align: center;
              cursor: pointer;
            }
          }

          .active {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #556080;
            width: 100%;
            justify-content: center;
            cursor: pointer;

            label {
              color: #FFFFFF !important;
              text-align: center;
              cursor: pointer;
            }

            input {
              display: none;
              background: #556080 !important;
              border: none;
              margin: 0;
              padding: 0;

            }
          }

          .active:first-child {
            border-radius: 5px 0 0 5px;
          }

          .active:last-child {
            border-radius: 0 5px 5px 0;
          }
        }

        .okr-to-tab-pane {
          display: flex;
          flex-direction: row;
          height: 38px;
          border-radius: 8px;
          background: #FFFFFF;

          .okr-to-type {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: center;
            cursor: pointer;

            label {
              color: #353535;
              text-align: center;
              cursor: pointer;
            }
          }

          .active {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #556080;
            width: 100%;
            justify-content: center;
            cursor: pointer;

            label {
              color: #FFFFFF !important;
              text-align: center;
              cursor: pointer;
            }

            input {
              display: none;
              background: #556080 !important;
              border: none;
              margin: 0;
              padding: 0;

            }
          }

          .active:first-child {
            border-radius: 5px 0 0 5px;
          }

          .active:last-child {
            border-radius: 0 5px 5px 0;
          }
        }

        .agreement-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 2rem;
          height: 100%;


          .date-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .date-wrapper {
              width: 100%;
            }
          }

          .service-type-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
          }
        }


      }

      .metadata-confirm-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .confirm-button {
          width: 10rem;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 30px;
          border-radius: 10px;
          background: #556080;
          color: #ffffff;
        }
      }
    }
  }

  .chatbot-field {
    width: 32%;
    height: 76vh;
    border-radius: 10px;
    background: #f0f0f0;

    .chatbot-header {
      height: 8vh;
      background: #556080;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      justify-content: space-between;
      padding: 15px 25px;
      align-items: center;

      .chatbot-side {
        display: flex;
        align-items: center;
        gap: 15px;

        .chatbot-image-wrapper {
          height: 58px;
          width: 58px;
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }

        .chatbot-text-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 5px;

          .chatbot-name {
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
          }

          .chatbot-status {
            font-size: 12px;
            font-weight: 300;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .chatbot-online {
              width: 5px;
              height: 5px;
              border: 1px solid green;
              background-color: green;
              border-radius: 50%;
            }
          }
        }
      }

      .reload-side {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .chatbot-body {
      height: 89%;
      background: transparent;
      width: 100%;
      overflow: auto;
      padding: 3vh 2vh;
      scrollbar-color: #556080 transparent !important;

      .chatbot-message-box {
        display: flex;
        flex-direction: column !important;
        flex-flow: wrap;
        gap: 1.5rem;


        .chatbot-question {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;


          .chatbot-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background: #556080;
            border-radius: 50%;

            img {
              width: 37px;
              height: 37px;
            }
          }

          .chatbot-question-text {
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #556080;
            flex-direction: column;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            padding: 10px 30px;
            border-radius: 8px;
          }
        }

        .chatbot-response {
          display: flex;
          flex-flow: wrap;
          flex-direction: row;
          gap: 10px;


          .chatbot-question-btn {
            height: 36px;
            background: transparent !important;
            border: 1px solid #556080;
            color: #556080;
            border-radius: 8px;
          }

          .active-question-btn {
            background: #556080 !important;
            color: #fff !important;
          }

          .new-answer-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .input-add-answer:focus {
              outline: none;
              border: 2px solid #556080;
            }

            .input-add-answer {
              height: 36px;
              background: #FFFFFF !important;
              border: 1px solid #556080;
              color: #556080;
              padding: 10px 30px;
              border-radius: 8px;
              text-align: center;
            }

            .confirm-buttons {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;

              .confirm {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                border: 1px solid #0AA06E;
                border-radius: 50%;
              }

              .cancel {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                border: 1px solid #851C1C;
                border-radius: 50%;
              }
            }
          }



        }
      }

      .user-message-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 30px;

        .user-answer {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          gap: 20px;
        }

        .user-image {

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }


        }

        .user-answer-text {
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #556080;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          padding: 10px 30px;
          border-radius: 8px;
        }

      }

      .new-category-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 10px 0;

        .input-new-category:focus {
          outline: none;
          border: 2px solid #556080;
        }

        .input-new-category {
          height: 36px;
          background: #FFFFFF !important;
          border: 1px solid #556080;
          color: #556080;
          padding: 10px 30px;
          border-radius: 8px;
          text-align: center;
        }



        .confirm-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .confirm {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid #0AA06E;
            border-radius: 50%;
          }

          .cancel {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid #851C1C;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.choice-field {
  width: 32%;
  height: 76vh;
  border-radius: 10px;
  background: #f0f0f0;


  .choice-header {
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #556080;
    margin: 0 10px;
    font-weight: 600;
    font-size: 1rem;
    color: #556080;
  }

  .choice-body {
    height: 89%;
    background: transparent;
    width: 100%;
    overflow: auto;
    padding: 1vh 2vh;
    scrollbar-color: #556080 transparent !important;

    .choice-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      border-bottom: 1px solid #556080;
      padding: 20px 0;


      .kpi-name-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
      }

      .kpi-name-input {
        height: 36px;
        border: 1px solid #556080;
        border-radius: 8px;
        padding: 10px 30px;
        text-align: center;
        width: 50%;
      }

      .choice-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-flow: wrap;
        gap: 10px;

        .choice-value {
          height: 36px;
          background: transparent;
          color: #556080;
          display: flex;
          border: 1px solid #556080;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          padding: 10px 30px;
        }
      }

      .action-button-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        gap: 10px;
        padding: 10px 15px;

        .edit-btn {
          height: 36px;
          border-radius: 8px;
          padding: 10px 30px;
          background: #0AA06E;
          color: #FFFFFF;
        }

        .delete-btn {
          height: 36px;
          border-radius: 8px;
          padding: 10px 30px;
          background: #A71F1F;
          color: #FFFFFF;
        }
      }
    }

    .save-choice-btn-wrapper {
      display: flex;
      width: 100%;
      margin-top: 20px;
      justify-content: flex-end;
      align-items: center;

      .save-choice-btn {
        height: 36px;
        background: #556080;
        padding: 10px 30px;
        text-align: center;
        border-radius: 8px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }
}

.custom-profile-card {
  position: absolute;
  top: 58px;
  background: linear-gradient(177.62deg, #090E2C 0%, #192051 99.67%);
  padding-top: 3vh;

  .text-colored {
    color: #E08ECA;
    font-weight: 500;
    font-size: 14px;
  }

  .text {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
  }

  .p-sidebar-content {
    max-height: 85vh !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .user-profile-information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2vh;

      .user-profile-picture {
        width: 14vh;
        height: 14vh;
        border-radius: 50%;
        border: 3px solid #E08ECA;
      }

    }

    .personal-information{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2vh;

      .icon-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1vw;
        padding: 0 1.5vw;
      }
    }
  }

  .information-divider{
    border-color: #E08ECA;
  }

  .user-profile-footer{
    margin-bottom: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vh;
  }
}

.custom-profile-card-enter  {
  animation: slide-down 0.3s ease-in-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.custom-profile-card-exit {
  animation: slide-up 0.3s ease-in-out;
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

// MEDIA QUERYLER

@media only screen and (min-height: 1100px) and (max-height: 1500px) {
  .responsive-header {
    margin-top: 0% !important;
  }
}

@media only screen and (min-height: 1500px) {
  .responsive-header {
    margin-top: 1.8% !important;
  }
}

@media only screen and (min-height: 1100px) and (max-height: 3500px) {
  .p-datatable .p-datatable-tbody tr {
    height: 6vh !important;
  }
}

@media only screen and (min-height: 700px) and (max-height: 1079px) {
  .p-datatable .p-datatable-tbody tr {
    min-height: 5vh !important;
  }

  .p-datatable .p-datatable-tbody tr td {
    padding: 0 1rem;
    height: 5.5vh !important;
  }

  .main-datatable {
    min-height: 75vh !important;
  }
}

@media only screen and (min-height: 1501px) {
  .responsive-user .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr {
    height: 5vh !important;
  }

  .responsive-header2 {
    margin-top: 1% !important;
  }
}

@media only screen and (min-height: 1081px) and (max-height: 1500px) {
  .responsive-user .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr {
    height: 5vh !important;
  }

  .responsive-header2 {
    margin-top: -1.2% !important;
  }
}

@media only screen and (min-height: 700px) and (max-height: 1079px) {
  .responsive-user .p-datatable-tbody tr {
    min-height: 5vh !important;
  }

  .responsive-user .p-datatable-tbody tr td {
    padding: 0 1rem;
    height: 4.8vh !important;
  }

  .main-datatable {
    min-height: 75vh !important;
  }
}

@media only screen and (min-height: 910px) and (max-height: 1080px) {
  .responsive-header2 {
    margin-top: -1.2% !important;
  }
}

@media only screen and (max-height: 910px) {
  .responsive-header2 {
    margin-top: -2.5% !important;
  }
}

@media only screen and (min-height: 950px) and (max-height: 1020px) {
  .left-area-detail {
    gap: 0;
  }
}

@media only screen and (max-height: 950px) {
  .permission-setting-left-area {
    gap: 1vh;
  }
}

// MEDIA QUERYLER

@media print {

  .layout-topbar,
  .layout-sidebar,
  .comment-container,
  .p-breadcrumb,
  .p-button-raised {
    display: none !important;
  }

  .print-container {
    padding: 1rem;
  }
}

.login-page {
  background-image: url("/images/bg.svg");
  background-size: cover;
}

.row-approved {
  background-color: var(--green-100) !important;
}

.list-demo {
  .product-name {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .product-description {
    margin: 0 0 1rem 0;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .product-category {
    font-weight: 600;
    vertical-align: middle;
  }

  .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;

    img {
      width: 150px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      margin-right: 2rem;
    }

    .product-list-detail {
      flex: 1 1 0;
    }

    .p-rating {
      margin: 0 0 0.5rem 0;
    }

    .product-price {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      align-self: flex-end;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .p-button {
      margin-bottom: 0.5rem;
    }
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  .product-grid-item {
    margin: 0.5em;
    border: 1px solid #dee2e6;

    .product-grid-item-top,
    .product-grid-item-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    img {
      width: 75%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      margin: 2rem 0;
    }

    .product-grid-item-content {
      text-align: center;
    }

    .product-price {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 576px) {
    .product-list-item {
      flex-direction: column;
      align-items: center;

      img {
        width: 75%;
        margin: 2rem 0;
      }

      .product-list-detail {
        text-align: center;
      }

      .product-price {
        align-self: center;
      }

      .product-list-action {
        display: flex;
        flex-direction: column;
      }

      .product-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
  }
}

.table-demo {
  .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
      background-color: #607d8b;
    }
  }

  .p-datatable .p-column-filter {
    display: none;
  }

  .table-header {
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
  }

  .p-datatable.p-datatable-customers {
    .p-datatable-header {
      padding: 1rem;
      text-align: left;
      font-size: 1.5rem;
    }

    .p-paginator {
      padding: 1rem;
      color: #737373;
    }

    .p-datatable-thead>tr>th {
      text-align: left;
    }

    .p-datatable-tbody>tr>td {
      cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }
  }

  /* Responsive */
  .p-datatable-customers .p-datatable-tbody>tr>td>.p-column-title {
    display: none;
  }

  .customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-qualified {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-unqualified {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-negotiation {
      background: #feedaf;
      color: #8a5340;
    }

    &.status-new {
      background: #b3e5fc;
      color: #23547b;
    }

    &.status-renewal {
      background: #eccfff;
      color: #694382;
    }

    &.status-proposal {
      background: #ffd8b2;
      color: #805b36;
    }
  }

  .p-progressbar-value.ui-widget-header {
    background: #607d8b;
  }

  @media (max-width: 640px) {
    .p-progressbar {
      margin-top: 0.5rem;
    }
  }

  .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .orders-subtable {
    padding: 1rem;
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  .order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
      background: #c8e6c9;
      color: #256029;
    }

    &.order-cancelled {
      background: #ffcdd2;
      color: #c63737;
    }

    &.order-pending {
      background: #feedaf;
      color: #8a5340;
    }

    &.order-returned {
      background: #eccfff;
      color: #694382;
    }
  }

  @media screen and (max-width: 960px) {
    .p-datatable {
      &.p-datatable-customers {

        .p-datatable-thead>tr>th,
        .p-datatable-tfoot>tr>td {
          display: none !important;
        }

        .p-datatable-tbody>tr {
          >td {
            text-align: left;
            display: block;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              font-weight: bold;
              text-transform: uppercase !important;
            }

            .p-progressbar {
              margin-top: 0.5rem;
            }
          }
        }

        colgroup {
          display: none;
        }
      }

      .p-datatable-header {
        .table-header {
          flex-direction: column !important;
        }
      }
    }
  }
}

.crud-demo {
  .p-toolbar {
    flex-wrap: wrap;
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  /* Responsive */
  .datatable-responsive .p-datatable-tbody>tr>td .p-column-title {
    display: none;
  }

  @media screen and (max-width: 960px) {
    .p-datatable {
      &.datatable-responsive {

        .p-datatable-thead>tr>th,
        .p-datatable-tfoot>tr>td {
          display: none !important;
        }

        .p-datatable-tbody>tr {
          border-bottom: 1px solid var(--surface-d);

          >td {
            text-align: left;
            display: flex;
            align-items: center;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              font-weight: bold;
              text-transform: uppercase !important;
            }

            .p-progressbar {
              margin-top: 0.5rem;
            }

            .actions {
              display: flex;
              flex-grow: 1;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.docs {
  i:not([class~="pi"]) {
    background-color: transparent;
    color: #2196f3;
    font-family: Monaco, courier, monospace;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    padding: 0 4px;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin: 0 2px;
    display: inline-flex;
  }

  a {
    font-weight: 500;
  }
}

pre[class*="language-"] {

  &:before,
  &:after {
    display: none !important;
  }

  code {
    border-left: 6px solid var(--surface-border) !important;
    box-shadow: none !important;
    background: var(--surface-ground) !important;
    margin: 1em 0;
    color: var(--text-color);
    font-size: 14px;
  }

  .token {

    &.tag,
    &.keyword {
      color: #2196f3 !important;
    }

    &.attr-name,
    &.attr-string {
      color: #2196f3 !important;
    }

    &.attr-value {
      color: #4caf50 !important;
    }

    &.punctuation {
      color: var(--text-color);
    }

    &.operator,
    &.variable,
    &.string {
      background: transparent;
    }
  }
}

.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.p-timeline-event-content,
.p-timeline-event-opposite {
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .customized-timeline {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }

    .p-card {
      margin-top: 1rem;
    }
  }
}

.custom-otp-input input {
  height: 40px !important;
  width: 40px !important;
}

.transform-component-module_wrapper__SPB86 {
  width: auto !important;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #3f4b5b;
  border-color: black;
}

.p-organizationchart .p-organizationchart-line-down {
  background: black;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #3f4b5b;
  border-color: black;
}