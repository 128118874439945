.layout-sidebar {
  position: fixed;
  width: 225px;
  height: 103%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  // top: 5rem;
  transition:
    transform $transitionDuration,
    left $transitionDuration;
    background: white;
    border-right: 1px dashed rgba(145, 158, 171, 0.2);
  // background: linear-gradient(-180deg, rgba(0, 146, 211) 0%, rgb(0, 58, 147) 100%);
  // border-right: 1px dashed rgba(145, 158, 171, 0.2);
  .version {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
  .layout-menu-container {
    overflow-y: auto;
    background: linear-gradient(180deg, rgba(9,14,44,1) 0%, rgba(25,32,81,1) 100%);
    height: 98%;
    scroll-snap-type: y mandatory;
    /* scroll-padding: top right bottom left */
    scroll-padding-top: 50px;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: lightgray;
      opacity: 0.5;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: lightgray;
      opacity: 0.5;
    }
  }
}



.layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  box-sizing: border-box;
  padding-top: 10px;
  li {
    margin-bottom: 15px;
    &.layout-menuitem-category {
      margin-top: 0.75rem;
      &:first-child {
        margin-top: 0;
      }
    }
    .menu-icon {
      font-size: 22px;
      margin-right: 17px;
    }

    .layout-menuitem-root-text {
      text-transform: uppercase;
      color: var(--text-color-secondary);
      font-weight: 400;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--text-color);
      transition: color $transitionDuration;
      border-radius: $borderRadius;
      padding: 0.35rem 1rem;
      transition: background-color 0.15s;

      span {
        // margin-left: 0.5rem;
        color: white;
      }

      .menuitem-toggle-icon {
        margin-left: auto;
      }

      &:focus {
        @include focused-inset();
      }

      &:hover {
        background-color: var(--surface-hover);
        // background-color: transparent;
        // // background-image: linear-gradient(180deg, #000f9f 0%, #002ca8 100%);
        // color: white;
      }    

      &.router-link-exact-active {
        font-weight: 600;
        & .pi {
          font-weight: 600;
        }      
      }
    }

    &.active-menuitem {
      > a {
        .menuitem-toggle-icon {
          &:before {
            content: "\e933";
          }
        }
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &.layout-submenu-wrapper-enter {
        max-height: 0;
      }

      &.layout-submenu-wrapper-enter-active {
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
      }

      &.layout-submenu-wrapper-enter-done {
        transform: none;
      }

      &.layout-submenu-wrapper-exit {
        max-height: 1000px;
      }

      &.layout-submenu-wrapper-exit-active {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
      }

      ul {
        // padding-left: 1rem;
      }
      & a {
        font-size: 13px;
        margin-bottom: 15px;
      }
    }
  }
}
.app-versiyon-container {
  position: absolute;
  width: 100%;
  bottom: 80px;
  left: 5px;
  text-align: center; 
  font-size: 12px;
}

.p-paginator-icon {
color: #737373;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
background-color: #2C60ADBF;
color: #ffffff;
}

.p-paginator .p-paginator-pages .p-paginator-page:hover {
background-color: #2C60ADBF;
color: #ffffff;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:hover {
background-color: #2C60ADBF;
color: #ffffff;
}
.p-datatable  {
  height: 100%;
}
.datatable {
  height: 100%;
}
.p-splitbutton-defaultbutton {
  display: none;
}
.custom-split-button .pi {
  color: black; /* Color for icons */
}

.p-splitbutton-menubutton{
  background: white;
}


.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only:hover {
  background-color: white;
  border-color: black;
}


.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only:focus {
  background-color: white;
  border-color: black;
  box-shadow: none; /* Remove the default focus shadow */
}


.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only:active {
  background-color: white !important;
  border-color: black;
}

.file-upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #d0d6f5;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  text-align: center;
}

.file-input {
  display: none;
}

.file-display-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.file-icon {
  font-size: 48px;
  margin-bottom: 8px;
}

.file-name {
  font-size: 16px;
  color: #333;
}

.file-upload-button {
  background-color: #59618e;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  text-align: center;
}

.file-upload-button:hover {
  background-color: #47507b;
}