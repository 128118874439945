.layout-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 320px);
    transition: margin-left $transitionDuration;      
    padding: 10px 10px 5px 10px;
    border-top: 1px solid var(--surface-border);
    z-index: 999;
    background-color: white;
    border-top: 1px dashed rgba(145, 158, 171, 0.2);
    font-size: 14px;
    color: var(--text-color);
    //background: linear-gradient(-180deg, rgba(255,255,255,0.70) 0%, rgba(208, 208, 208) 100%);
 
    &.fullwidth {
        width: 100%;
    }
}
